var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar", staticStyle: { display: "inline" } },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "h3",
        {
          staticClass: "text-center",
          staticStyle: { display: "inline-block", width: "70%" },
        },
        [_vm._v(_vm._s(_vm.store_name) + " - " + _vm._s(_vm.name))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "right-menu" }, [
        _c(
          "a",
          {
            staticClass: "sm",
            staticStyle: { "margin-right": "20px", display: "inline" },
            attrs: { type: "success" },
            on: { click: _vm.logout },
          },
          [_vm._v("\n      Log out\n    ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }