import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import { getToken, getUrl } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
});
// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (getToken()) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token'] = getToken();
  }
  if (getUrl()) {
    config.params = {
      'url': getUrl()
    };
  }
  return config;
}, function (error) {
  console.log(error);
  // do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 200) {
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log(error);
  if (error.response && error.response.status === 401) {
    store.dispatch('user/resetToken').then(function () {
      location.reload();
    });
  }
  var message = 'Terjadi kesalahan';
  if (error.response && error.response.data) {
    if (error.response.status === 400) {
      for (var _i = 0, _Object$keys = Object.keys(error.response.data.data); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        message = error.response.data.data[key][0];
      }
    } else {
      message = error.response.data.message;
    }
  }
  Message({
    message: message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;