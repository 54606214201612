import { setUrl } from '@/utils/auth';
var state = {
  store_url: ''
};
var mutations = {
  SET_STORE_URL: function SET_STORE_URL(state, store_url) {
    state.store_url = store_url;
  }
};
var actions = {
  setStoreUrl: function setStoreUrl(_ref, data) {
    var commit = _ref.commit;
    commit('SET_STORE_URL', data);
    setUrl(data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};