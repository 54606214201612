import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  storeUrl: function storeUrl(state) {
    return state.store.store_url;
  },
  isOnline: function isOnline(state) {
    return state.connection.is_online;
  },
  formId: function formId(state) {
    return state.form.formId;
  },
  permissions_pos: function permissions_pos(state) {
    return state.user.permissions;
  },
  active_store: function active_store(state) {
    return state.user.active_store;
  },
  stores: function stores(state) {
    return state.user.stores;
  }
};
export default getters;