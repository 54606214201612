var state = {
  is_online: true
};
var mutations = {
  SET_IS_ONLINE: function SET_IS_ONLINE(state, data) {
    state.is_online = data;
  }
};
var actions = {
  setIsOnline: function setIsOnline(_ref, data) {
    var commit = _ref.commit;
    commit('SET_IS_ONLINE', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};