import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: '/me',
    method: 'get'
  });
}
export function getListUser() {
  return request({
    url: '/users',
    method: 'post',
    data: {
      'sortBy': 'username',
      'desc': false
    }
  });
}
export function logout() {
  return request({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  });
}