import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/builds/gifary.upwork/pos-fixprint/node_modules/core-js/modules/es6.array.iterator.js";
import "/builds/gifary.upwork/pos-fixprint/node_modules/core-js/modules/es6.promise.js";
import "/builds/gifary.upwork/pos-fixprint/node_modules/core-js/modules/es6.object.assign.js";
import "/builds/gifary.upwork/pos-fixprint/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters
import VueOffline from 'vue-offline';
import VueCookies from 'vue-cookies';
import VueHtmlToPaper from 'vue-html-to-paper';
import locale from 'element-ui/lib/locale/lang/en';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
Sentry.init({
  Vue: Vue,
  dsn: 'https://e84a1ab8157b4408809e27dac47e15ae@o455981.ingest.sentry.io/5704626',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});
moment.tz.setDefault('Asia/Jakarta');
var options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: ['https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css', 'https://unpkg.com/kidlat-css/css/kidlat.css']
};
Vue.use(VueHtmlToPaper, options);
Vue.use(Element, {
  locale: locale
});
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.use(VueOffline);
Vue.use(VueCookies);
Vue.$cookies.config('7d');

// set global cookie
Vue.$cookies.set('theme', 'default');
Vue.$cookies.set('hover-time', '1s');
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});