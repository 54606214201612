import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var urlAccess = 'URLAccess';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getUrl() {
  return Cookies.get(urlAccess);
}
export function setUrl(token) {
  return Cookies.set(urlAccess, token);
}
export function removeUrl() {
  return Cookies.remove(urlAccess);
}