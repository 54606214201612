export default {
  namespaced: true,
  state: {
    formId: null
  },
  mutations: {
    SET_FORM_ID: function SET_FORM_ID(state, id) {
      state.formId = id;
    }
  },
  actions: {
    setFormId: function setFormId(_ref, data) {
      var commit = _ref.commit;
      commit('SET_FORM_ID', data);
    }
  }
};