import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/order',
  redirect: '/order/list',
  component: Layout,
  name: 'Order',
  meta: {
    title: 'Order',
    icon: 'list'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/order/list');
    },
    name: 'Orders',
    meta: {
      title: 'Order',
      icon: 'list'
    },
    hidden: false
  }, {
    path: 'create',
    component: function component() {
      return import('@/views/order/create');
    },
    name: 'CreateOrder',
    meta: {
      title: 'Create Order',
      icon: 'edit',
      activeMenu: '/order/create'
    },
    hidden: false
  }, {
    path: ':id/edit',
    component: function component() {
      return import('@/views/order/edit');
    },
    name: 'EditOrder',
    meta: {
      title: 'Edit Order',
      icon: 'edit',
      activeMenu: '/order/:id/edit'
    },
    hidden: true
  }, {
    path: ':id/quick-edit',
    component: function component() {
      return import('@/views/order/quick_edit');
    },
    name: 'QuickEditOrder',
    meta: {
      title: 'Quick Edit Order',
      icon: 'edit',
      activeMenu: '/order/:id/quick-edit'
    },
    hidden: true
  }, {
    path: 'import',
    component: function component() {
      return import('@/views/order/import');
    },
    name: 'ImportOrder',
    meta: {
      title: 'Import Order',
      icon: 'edit',
      activeMenu: '/order/create'
    },
    hidden: false
  }, {
    path: 'print',
    component: function component() {
      return import('@/views/order/detail');
    },
    name: 'PrintOrder',
    meta: {
      title: 'Print Order',
      icon: 'print',
      activeMenu: '/order/print'
    },
    hidden: true
  }, {
    path: 'print-multiple',
    component: function component() {
      return import('@/views/order/detail_multiple');
    },
    name: 'DetailMultiple',
    meta: {
      title: 'Print Order Multiple',
      icon: 'print',
      activeMenu: '/order/print-multiple'
    },
    hidden: true
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;